<template>
    <div>
        <header-portrate />

        <div class="m-2">
            <bread-crumbs :items="items"></bread-crumbs> 
            <tabs-comp :tabs="tabs" style="margin-top:60px !important;" />
            
            <div :style="`direction:`+lang.dir+`;margin:5px;margin-top:10px;margin-left:30px;margin-right:30px;`">
                <div :style="`direction:`+lang.dir+`;float:`+lang.lalgin+`;`+lang.lalgin+`:10px;margin-`+lang.lalgin+`:12px;`">
                    <button id="multiply-button" :class="`multiply-button-`+lang.lalgin" @click="addVendor()" v-b-toggle.add_vendor>
                        <div style="width:150px" :class="`multiply-button-content-`+lang.lalgin">{{lang.add_vendor}}</div>
                    </button>
                </div>
                <div style="clear:both;margin-bottom:5px;"></div>
                <v-simple-table class="mt-5">
                    <thead>
                        <tr>
                            <th class="text-center" v-for="(item,index) in headers" :key="index">{{item.text}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in vendorrows" :key="index">
                            <td class="text-center">{{item.vendorid}}</td>
                            <td class="text-center">{{item.full_name}}</td>
                            <td class="text-center">{{item.mobile}}</td>
                            <td class="text-center">{{item.address}}</td>
                            <td class="text-center">{{item.vatid}}</td>
                            <td class="text-center">{{item.invtotal}}</td>
                            <td class="text-center">{{item.vattotal}}</td>
                            <td class="text-center" style="width:280px;padding:3px !important;">
                                <b-button class="btn btn-sm" @click="doEdit(item)" v-b-toggle.add_vendor style="background:#000;color:#FFF;width:90px !important;margin-inline-end:5px;">{{lang.update}}</b-button>
                                <b-button class="btn btn-sm" @click="doStatement(item)" v-b-toggle.vendor_ids style="background:red;color:#FFF;width:90px !important;margin-inline-end:5px;">{{lang.statment}}</b-button>
                                <b-button class="btn btn-sm" @click="doAddinv(item)" v-b-toggle.add_addporch style="background:green;color:#FFF;width:90px !important;">{{lang.add_invoice}}</b-button>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </div>
        <purchase ref="addBill" />
        <vendor-statement ref="getStatement" />
        <add-vendro ref="addVend" />
        <Footer />
    </div>
</template>

<script>
import axios from 'axios'
import HeaderPortrate from '@/components/Header-Portrate.vue';
import BreadCrumbs from '@/components/breadCrumbs.vue';
import TabsComp from '@/components/tabsComp.vue';
import Purchase from '@/components/Purchase.vue';
import VendorStatement from './vendorStatement.vue';
import AddVendro from '@/components/addVendro.vue';
import Footer from '@/components/Footer.vue';
export default {
    components: { HeaderPortrate, BreadCrumbs, TabsComp, Purchase,VendorStatement, AddVendro, Footer },
    data() {
        return {
            vendorrows: [],
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        headers: function(){
            return [
                {text:this.lang.vendorid},
                {text:this.lang.vendor_name},
                {text:this.lang.vendor_mobile},
                {text:this.lang.vendor_address},
                {text:this.lang.vendor_vatids},
                {text:this.lang.invoice_total},
                {text:this.lang.vat_total},
                {text:this.lang.action},
            ]
        },
        items: function() {
            return {
                    text: this.lang.vendors,
                    disabled: true,
                    to: '/inventory/vendors',
                }
        },
        tabs: function() {
            let x = [];
            x.push(
                {
                    index:0,name:this.lang.vendors,href:'/inventory/vendors',class:'mytab',
                }
            );
            x.push(
                {
                    index:0,name:this.lang.purchase,href:'/inventory/purchase',class:'mytab3',
                }
            );
            if(this.$store.state.group_id != 3){
               
                x.push({
                    index:1,name:this.lang.product_categories,href:'/inventory/products-cats', class: 'mytab6'
                }
                );
                x.push({
                    index:2,name:this.lang.products,href:'/inventory/products', class: 'mytab1'
                });
                x.push({
                    index:3,name:' الاكثر مبيعا',href:'/inventory/best-seller', class: 'mytab2'
                });
                x.push({
                    index:4,name:' الاقل مبيعا',href:'/inventory/lowest-selling', class: 'mytab3'
                });
                x.push({
                    index:5,name:'الاصناف المنتهية',href:'/inventory/finished', class: 'mytab4'
                })
                x.push({
                    index:5,name:'حركة صنف',href:'/inventory/itemtrans', class: 'mytab4'
                })
            }
            return x;
        },
    },
    created() {
        this.getVendors();
    },
    methods: {
        addVendor(){
            this.$refs.addVend.vendorid = ''
            this.$refs.addVend.full_name = ''
            this.$refs.addVend.vatid = ''
            this.$refs.addVend.address = ''
            this.$refs.addVend.mobile = ''
            this.$refs.addVend.formtitle = this.lang.add_vendor
            this.$refs.addVend.SUBMIT_NAME = this.lang.add
        },
        doEdit(item){
            this.$refs.addVend.vendorid = item.vendorid
            this.$refs.addVend.full_name = item.full_name
            this.$refs.addVend.vatid = item.vatid
            this.$refs.addVend.address = item.address
            this.$refs.addVend.mobile = item.mobile
            this.$refs.addVend.formtitle = this.lang.update_vendor
            this.$refs.addVend.SUBMIT_NAME = this.lang.update
        },
        doStatement(item){
            this.$refs.getStatement.accountn = item;
        },
        doAddinv(item){
            this.$refs.addBill.vendorid = item.vendorid;
            this.$refs.addBill.vendorCheck();
        },
        getVendors(){
            const post = new FormData();
            post.append('type','getVendors');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase))
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki))

            axios.post(
                this.$store.state.SAMCOTEC.r_path,post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.vendorrows = res.results.data;
            })
        }
    },
}
</script>